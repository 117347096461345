#welcpme-pg {
  background-color: #fff;
}
#welcpme-pg .header {
  position: fixed;
  width: 100%;
  height: 80px;
  z-index: 1050;
  margin-top: 20px;
}
#welcpme-pg .header .header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#welcpme-pg .header .header-top .logocont {
  flex: 1;
  padding: 0px;
  margin: 0px;
  height: 80px;
  position: relative;
}
#welcpme-pg .header .header-top .logocont h2 {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  line-height: 80px;
  color: #2a7790;
  font-weight: 600;
  padding-left: 25px;
  margin: 0;
}
#welcpme-pg .header .header-top .logocont img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
}
#welcpme-pg .header .header-top .menucont {
  flex: 3;
  background-color: #eaeded;
  padding: 0px 20px;
  height: 100%;
}
#welcpme-pg .body-wrp {
  font-family: "Poppins", sans-serif;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6rem;
}
#welcpme-pg .body-wrp .span1 {
  content: "";
  position: absolute;
  top: -5rem;
  right: -5rem;
  display: block;
  width: 280px;
  height: 280px;
  border-radius: 50%;
  background: linear-gradient(
    210deg,
    rgba(32, 157, 170, 0.5) 30%,
    rgba(255, 255, 255, 0) 100%
  );
}
#welcpme-pg .body-wrp .span2 {
  content: "";
  position: absolute;
  bottom: 5rem;
  left: 1.25rem;
  display: block;
  width: 600px;
  height: 600px;
  border-radius: 50%;
  background: linear-gradient(
    135deg,
    rgba(166, 211, 223, 0.5) 0%,
    rgba(255, 255, 255, 0) 70%
  );
  -webkit-animation: infade 1s cubic-bezier(0.27, 0.08, 0.29, 1) -100ms;
  animation: infade 1s cubic-bezier(0.27, 0.08, 0.29, 1) -100ms;
  transform: translate(0px, 0px);
  transform: scale(1);
}
#welcpme-pg .body-wrp .applicant-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
#welcpme-pg .body-wrp .title {
  display: block;
  width: 28.5rem;
  z-index: 1;
}
#welcpme-pg .body-wrp .title .logocont {
  flex: 1;
  padding: 0px;
  margin: 0px;
  height: 5rem;
  position: relative;
  margin-bottom: 0.5rem;
  -webkit-animation: infromTop 1s cubic-bezier(0.27, 0.08, 0.29, 1) -100ms;
  animation: infromTop 1s cubic-bezier(0.27, 0.08, 0.29, 1) -100ms;
  transform: translate(0px, 0px);
  transform: scale(1);
}
#welcpme-pg .body-wrp .title .logocont img {
  position: absolute;
  left: 46%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
}
#welcpme-pg .body-wrp .title h2 {
  font-family: "Poppins", sans-serif;
  font-family: "Overpass", sans-serif;
  font-size: 1.8rem;
  color: #344457;
  font-weight: 600;
  margin-bottom: 1rem;
  -webkit-animation: infromLeft 1s cubic-bezier(0.27, 0.08, 0.29, 1) -100ms;
  animation: infromLeft 1s cubic-bezier(0.27, 0.08, 0.29, 1) -100ms;
  transform: translate(0px, 0px);
  transform: scale(1);
}
#welcpme-pg .body-wrp .title p {
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #a4b0bc;
  font-weight: 400;
  -webkit-animation: infromRight 1s cubic-bezier(0.27, 0.08, 0.29, 1) -100ms;
  animation: infromRight 1s cubic-bezier(0.27, 0.08, 0.29, 1) -100ms;
  transform: translate(0px, 0px);
  transform: scale(1);
}
#welcpme-pg .body-wrp .title .join__btns {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2.5rem;
}
#welcpme-pg .body-wrp .title .join__btns .loginBtn {
  min-width: 140px;
  margin: 0 0.5rem;
}
#welcpme-pg .body-wrp .title .join__btns .loginBtn button {
  width: 100%;
  padding: 0.75rem;
  background-color: #078ece;
  border: none;
  border-radius: 1.5rem;
  color: #f6f8fa;
  text-align: center;
  font-size: 0.75rem;
  text-transform: uppercase;
  transition: all 300ms;
}
#welcpme-pg .body-wrp .title .join__btns .loginBtn button:hover {
  background-color: #2a7790;
  box-shadow: 0px 5px 5px 0px rgba(52, 68, 87, 0.2);
  -moz-box-shadow: 0px 5px 5px 0px rgba(52, 68, 87, 0.2);
  -webkit-box-shadow: 0px 5px 5px 0px rgba(52, 68, 87, 0.2);
}
#welcpme-pg .body-wrp .title .join__btns .loginBtn:first-child {
  -webkit-animation: infromBottom 1s cubic-bezier(0.27, 0.08, 0.29, 1) -100ms;
  animation: infromBottom 1s cubic-bezier(0.27, 0.08, 0.29, 1) -100ms;
  transform: translate(0px, 0px);
  transform: scale(1);
}
#welcpme-pg .body-wrp .title .join__btns .loginBtn:last-child {
  -webkit-animation: infromBottom 1s cubic-bezier(0.27, 0.08, 0.29, 1) -40ms;
  animation: infromBottom 1s cubic-bezier(0.27, 0.08, 0.29, 1) -40ms;
  transform: translate(0px, 0px);
  transform: scale(1);
}
#welcpme-pg .body-wrp .cards {
  z-index: 1;
}
#welcpme-pg .body-wrp .cards .cards-s {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1rem;
}
#welcpme-pg .body-wrp .cards .cards-s .staticard {
  width: 245px;
  border-radius: 1.5rem;
  background-color: #eaeded;
  background-color: #fff;
  padding: 1.5rem;
  box-shadow: 0px 0px 15px 0px rgba(52, 68, 87, 0.1);
  -moz-box-shadow: 0px 0px 15px 0px rgba(52, 68, 87, 0.1);
  -webkit-box-shadow: 0px 0px 15px 0px rgba(52, 68, 87, 0.1);
}
#welcpme-pg .body-wrp .cards .cards-s .staticard h2 {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  /* color: #fabc07;
	*/
  color: #078ece;
  font-weight: 400;
  margin-bottom: 1rem;
}
#welcpme-pg .body-wrp .cards .cards-s .staticard .imgcont {
  display: block;
  width: 100%;
  height: 8.75rem;
}
#welcpme-pg .body-wrp .cards .cards-s .staticard .imgcont img {
  width: 100%;
}
#welcpme-pg .body-wrp .cards .cards-s .staticard:first-child {
  -webkit-animation: infromTop 1s cubic-bezier(0.27, 0.08, 0.29, 1) -100ms;
  animation: infromTop 1s cubic-bezier(0.27, 0.08, 0.29, 1) -100ms;
  transform: translate(0px, 0px);
  transform: scale(1);
}
#welcpme-pg .body-wrp .cards .cards-s .staticard:last-child {
  -webkit-animation: infromTop 1s cubic-bezier(0.27, 0.08, 0.29, 1) -10ms;
  animation: infromTop 1s cubic-bezier(0.27, 0.08, 0.29, 1) -10ms;
  transform: translate(0px, 0px);
  transform: scale(1);
}
#welcpme-pg .body-wrp .cards .staticard-l {
  flex: 2;
  z-index: 1;
  border-radius: 1.5rem;
  background-color: #078ece;
  padding: 1.5rem;
  box-shadow: 0px 0px 5px 0px rgba(52, 68, 87, 0.2);
  -moz-box-shadow: 0px 0px 5px 0px rgba(52, 68, 87, 0.2);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(52, 68, 87, 0.2);
  -webkit-animation: infromBottom 1s cubic-bezier(0.27, 0.08, 0.29, 1) -100ms;
  animation: infromBottom 1s cubic-bezier(0.27, 0.08, 0.29, 1) -100ms;
  transform: translate(0px, 0px);
  transform: scale(1);
}
#welcpme-pg .body-wrp .cards .staticard-l h2 {
  font-family: "Poppins", sans-serif;
  font-size: 1.25rem;
  color: #eaeded;
  font-weight: 400;
  margin-bottom: 1rem;
}
#welcpme-pg .body-wrp .cards .staticard-l .imgcont {
  display: block;
  width: 28rem;
}
#welcpme-pg .body-wrp .cards .staticard-l .imgcont img {
  width: 100%;
}

.modal-card {
  border-radius: 1.25rem;
}

.modal-dialog {
  max-width: 35rem;
}
.signup-modal {
  max-width: 40rem;
}
.signup-modal .sidebar {
  width: calc(49rem - (35rem / 1.618));
  min-width: calc(49rem - (35rem / 1.618));
}
.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: 1.25rem;
  outline: 0;
}
.modal-content .modal-body {
  width: 100%;
  position: relative;
  padding: 0;
}
.modal-content .modal-body .closebtn {
  position: absolute;
  top: -1.5rem;
  right: -1.5rem;
  display: block;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: #f6f8fa;
  text-align: center;
  line-height: 1.75rem;
  color: #078ece;
  font-weight: 600;
  opacity: 0.5;
  transition: opacity 300ms ease-in-out;
}
.modal-content .modal-body .closebtn:hover {
  opacity: 0.9;
}
.modal-content .modal-body .closebtn:hover .havTultip {
  top: -4rem;
  visibility: visible;
  opacity: 1;
}

.modal-content .modal-body .logincont {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 1.25rem;
}
.modal-content .modal-body .logincont .loginwrp {
  display: flex;
}
.modal-content .modal-body .logincont .loginwrp .sidebar {
  width: calc(35rem - (35rem / 1.618));
  min-width: calc(35rem - (35rem / 1.618));
  background-color: rgba(166, 211, 223, 0.25);
  position: relative;
  overflow: hidden;
}
.modal-content .modal-body .logincont .loginwrp .sidebar .span_3 {
  content: "";
  position: absolute;
  bottom: 0.25rem;
  left: -21.5rem;
  display: block;
  width: 31.25rem;
  height: 31.25rem;
  border-radius: 50%;
  background: linear-gradient(
    130deg,
    rgba(32, 157, 172, 0.75) 30%,
    rgba(246, 248, 250, 0) 100%
  );
}
.modal-content .modal-body .logincont .loginwrp .loginform {
  width: 100%;
  padding: 2.5rem;
}
.modal-content .modal-body .logincont .loginwrp .loginform .logincont__header {
  width: 100%;
  height: 5rem;
  position: relative;
}
.modal-content
  .modal-body
  .logincont
  .loginwrp
  .loginform
  .logincont__header
  h2 {
  font-family: "Lato", sans-serif;
  text-align: left;
  color: #078ece;
  font-weight: 700;
  margin: 0;
  font-size: 1.5rem;
}
.modal-content
  .modal-body
  .logincont
  .loginwrp
  .loginform
  .logincont__header
  p {
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: #a4b0bc;
}
.modal-content
  .modal-body
  .logincont
  .loginwrp
  .loginform
  .logincont__header
  .employbtn {
  position: absolute;
  top: 0;
  right: 0;
}
.modal-content
  .modal-body
  .logincont
  .loginwrp
  .loginform
  .logincont__form
  .login--filed {
  margin-bottom: 0.5rem;
}
.modal-content
  .modal-body
  .logincont
  .loginwrp
  .loginform
  .logincont__form
  .login--filed
  label {
  font-size: 0.75rem;
  padding-left: 0.5rem;
  margin: 0;
}
.modal-content
  .modal-body
  .logincont
  .loginwrp
  .loginform
  .logincont__form
  .login--filed
  input {
  width: 100%;
  height: 2rem;
  border: 1px solid rgba(32, 157, 172, 0.75);
  border-radius: 0.5rem;
  background-color: transparent;
  padding: 0 1rem;
  line-height: 2rem;
  font-size: 0.875rem;
  transition: all 300ms ease;
}
.modal-content
  .modal-body
  .logincont
  .loginwrp
  .loginform
  .logincont__form
  .login--filed
  input:focus {
  box-shadow: 0px 5px 5px 0px rgba(52, 68, 87, 0.1);
  -moz-box-shadow: 0px 5px 5px 0px rgba(52, 68, 87, 0.1);
  -webkit-box-shadow: 0px 5px 5px 0px rgba(52, 68, 87, 0.1);
}
.modal-content
  .modal-body
  .logincont
  .loginwrp
  .loginform
  .logincont__form
  .inlinefiels {
  display: flex;
  justify-content: space-between;
}
.modal-content
  .modal-body
  .logincont
  .loginwrp
  .loginform
  .logincont__form
  .inlinefiels
  .login--filed {
  width: 48%;
}
.modal-content
  .modal-body
  .logincont
  .loginwrp
  .loginform
  .logincont__form
  .emplofield {
  display: flex;
  justify-content: center;
}
.modal-content
  .modal-body
  .logincont
  .loginwrp
  .loginform
  .logincont__form
  .emplofield
  .login--filed {
  width: 80%;
}
.modal-content
  .modal-body
  .logincont
  .loginwrp
  .loginform
  .logincont__form
  .loginsubBtn {
  width: 75%;
  margin: 2rem auto 1.5rem;
  display: flex;
  justify-content: center;
}
.modal-content
  .modal-body
  .logincont
  .loginwrp
  .loginform
  .logincont__form
  .loginsubBtn
  button {
  width: 100%;
  height: 2rem;
  line-height: 1.875rem;
  text-align: center;
  border-radius: 1.5rem;
  border: 1px solid #078ece;
  font-size: 0.75rem;
  margin: 0 0.25rem;
  text-transform: uppercase;
  transition: all 300ms;
}
.modal-content
  .modal-body
  .logincont
  .loginwrp
  .loginform
  .logincont__form
  .forgotbBtn {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
}
.modal-content
  .modal-body
  .logincont
  .loginwrp
  .loginform
  .logincont__form
  .forgotbBtn
  button:last-child {
  width: 100%;
  text-align: right;
  background-color: transparent;
  border: none;
  color: #2a7790;
  font-size: 0.75rem;
  text-transform: capitalize;
}
.modal-content
  .modal-body
  .logincont
  .loginwrp
  .loginform
  .logincont__form
  .forgotbBtn
  button:last-child:hover {
  color: #078ece;
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  #welcpme-pg {
    background-color: #fff;
  }

  #welcpme-pg .body-wrp {
    width: 100%;
    height: 100vh;
    padding: 2rem;
  }
  #welcpme-pg .body-wrp .span1 {
    display: none;
  }
  #welcpme-pg .body-wrp .span2 {
    content: "";
    position: absolute;
    top: -20%;
    left: 50%;
    display: block;
    width: 580px;
    height: 580px;
    border-radius: 50%;
    background: linear-gradient(
      135deg,
      rgba(166, 211, 223, 0.75) 0%,
      rgba(255, 255, 255, 0) 70%
    );
    -webkit-animation: infade 1s cubic-bezier(0.27, 0.08, 0.29, 1) -100ms;
    animation: infade 1s cubic-bezier(0.27, 0.08, 0.29, 1) -100ms;
    transform: translateX(-50%) scale(1);
  }
  #welcpme-pg .body-wrp .applicant-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  #welcpme-pg .body-wrp .title {
    display: block;
    width: 100%;
    z-index: 1;
  }
  #welcpme-pg .body-wrp .title .logocont {
    flex: 1;
    padding: 0px;
    margin: 0px;
    height: 4rem;
    position: relative;
    margin-bottom: 1.2rem;
    -webkit-animation: infromTop 1s cubic-bezier(0.27, 0.08, 0.29, 1) -100ms;
    animation: infromTop 1s cubic-bezier(0.27, 0.08, 0.29, 1) -100ms;
    transform: translate(0px, 0px);
    transform: scale(1);
  }
  #welcpme-pg .body-wrp .title .logocont img {
    position: absolute;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  #welcpme-pg .body-wrp .title h2 {
    font-size: 1.6rem;
    text-align: center;
    margin-bottom: 1rem;
  }
  #welcpme-pg .body-wrp .title p {
    font-size: 1rem;
    text-align: center;
  }
  #welcpme-pg .body-wrp .title .join__btns {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 1.5rem;
  }
  #welcpme-pg .body-wrp .title .join__btns .loginBtn {
    min-width: 140px;
    margin: 0 0.5rem;
  }
  #welcpme-pg .body-wrp .title .join__btns .loginBtn button {
    width: 100%;
    padding: 0.5rem 0.75rem;
  }
  #welcpme-pg .body-wrp .cards {
    z-index: 1;
    margin-top: 5rem;
  }
  #welcpme-pg .body-wrp .cards .cards-s {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  #welcpme-pg .body-wrp .cards .cards-s .staticard {
    width: 145px;
    border-radius: 1rem;
    background-color: #eaeded;
    background-color: #fff;
    padding: 1rem;
    box-shadow: 0px 0px 15px 0px rgba(52, 68, 87, 0.1);
    -moz-box-shadow: 0px 0px 15px 0px rgba(52, 68, 87, 0.1);
    -webkit-box-shadow: 0px 0px 15px 0px rgba(52, 68, 87, 0.1);
  }
  #welcpme-pg .body-wrp .cards .cards-s .staticard h2 {
    font-family: "Poppins", sans-serif;
    font-size: 0.85rem;

    color: #078ece;
    font-weight: 400;
    margin-bottom: 0.85rem;
  }
  #welcpme-pg .body-wrp .cards .cards-s .staticard .imgcont {
    display: block;
    width: 100%;
    height: 100%;
  }
  #welcpme-pg .body-wrp .cards .cards-s .staticard .imgcont img {
    width: 100%;
  }
  #welcpme-pg .body-wrp .cards .cards-s .staticard:first-child {
    -webkit-animation: infromTop 1s cubic-bezier(0.27, 0.08, 0.29, 1) -100ms;
    animation: infromTop 1s cubic-bezier(0.27, 0.08, 0.29, 1) -100ms;
    transform: translate(0px, 0px);
    transform: scale(1);
  }
  #welcpme-pg .body-wrp .cards .cards-s .staticard:last-child {
    -webkit-animation: infromTop 1s cubic-bezier(0.27, 0.08, 0.29, 1) -10ms;
    animation: infromTop 1s cubic-bezier(0.27, 0.08, 0.29, 1) -10ms;
    transform: translate(0px, 0px);
    transform: scale(1);
  }
  #welcpme-pg .body-wrp .cards .staticard-l {
    display: none;
  }
  .signup-modal {
    max-width: 49rem;
  }
  .signup-modal .sidebar {
    display: none;
    width: calc(49rem - (35rem / 1.618));
    min-width: calc(49rem - (35rem / 1.618));
  }
  .modal-content {
    max-width: 320px;
    margin: 0 auto;
  }
  .modal-content .modal-body .closebtn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
  .modal-content .modal-body .logincont .loginwrp {
    display: flex;
  }
  .modal-content .modal-body .logincont .loginwrp .sidebar {
    display: none;
  }
  .modal-content .modal-body .logincont .loginwrp .loginform {
    width: 100%;
    padding: 2rem;
  }
}

.loginwrp {
  display: flex !important;
}

.sidebar {
  width: calc(35rem - (35rem / 1.618));
  min-width: calc(35rem - (35rem / 1.618));
  background-color: rgba(166, 211, 223, 0.25);
  position: relative;
  overflow: hidden;
}
.sidebar .span_3 {
  content: "";
  position: absolute;
  bottom: 0.25rem;
  left: -21.5rem;
  display: block;
  width: 31.25rem;
  height: 31.25rem;
  border-radius: 50%;
  background: linear-gradient(
    130deg,
    rgba(32, 157, 172, 0.75) 30%,
    rgba(246, 248, 250, 0) 100%
  );
}

.loginform {
  width: 100%;
  padding: 2.5rem;
}

.loginform .logincont__header {
  width: 100%;
  height: 5rem;
  position: relative;
}

.loginform {
  width: 100%;
  padding: 2.5rem;
}
.loginform .logincont__header {
  width: 100%;
  height: 5rem;
  position: relative;
}
.loginform .logincont__header h2 {
  font-family: "Lato", sans-serif;
  text-align: left;
  color: #078ece;
  font-weight: 700;
  margin: 0;
  font-size: 1.5rem;
}
.loginform .logincont__header p {
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: #a4b0bc;
}
.loginform .logincont__header .employbtn {
  position: absolute;
  top: 0;
  right: 0;
}
.loginform .logincont__form .login--filed {
  margin-bottom: 0.5rem;
}
.loginform .logincont__form .login--filed label {
  font-size: 0.75rem;
  padding-left: 0.5rem;
  margin: 0;
}
.loginform .logincont__form .login--filed input {
  width: 100%;
  height: 2rem;
  border: 1px solid rgba(32, 157, 172, 0.75);
  border-radius: 0.5rem;
  background-color: transparent;
  padding: 0 1rem;
  line-height: 2rem;
  font-size: 0.875rem;
  transition: all 300ms ease;
}
.loginform .logincont__form .login--filed input:focus {
  box-shadow: 0px 5px 5px 0px rgba(52, 68, 87, 0.1);
  -moz-box-shadow: 0px 5px 5px 0px rgba(52, 68, 87, 0.1);
  -webkit-box-shadow: 0px 5px 5px 0px rgba(52, 68, 87, 0.1);
}
.loginform .logincont__form .inlinefiels {
  display: flex;
  justify-content: space-between;
}
.loginform .logincont__form .inlinefiels .login--filed {
  width: 48%;
}
.loginform .logincont__form .emplofield {
  display: flex;
  justify-content: center;
}
.loginform .logincont__form .emplofield .login--filed {
  width: 80%;
}
.loginform .logincont__form .loginsubBtn {
  width: 75%;
  margin: 2rem auto 1.5rem;
  display: flex;
  justify-content: center;
}
.loginform .logincont__form .loginsubBtn button {
  width: 100%;
  height: 2rem;
  line-height: 1.875rem;
  text-align: center;
  border-radius: 1.5rem;
  border: 1px solid #078ece;
  font-size: 0.75rem;
  margin: 0 0.25rem;
  text-transform: uppercase;
  transition: all 300ms;
}
.loginform .logincont__form .forgotbBtn {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
}
.loginform .logincont__form .forgotbBtn button:last-child {
  width: 100%;
  text-align: right;
  background-color: transparent;
  border: none;
  color: #2a7790;
  font-size: 0.75rem;
  text-transform: capitalize;
}
.loginform .logincont__form .forgotbBtn button:last-child:hover {
  color: #078ece;
}

.pri__button {
  background-color: #078ece;
  color: #f6f8fa;
  border: 1px solid #078ece;
}

.closebtn {
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  cursor: pointer;

  text-align: center;
  line-height: 1.75rem;

  font-weight: 600;
  color: #078ece;

  font-family: Roboto, "Helvetica Neue", sans-serif;

  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.closebtn:hover {
  background-color: rgba(166, 211, 223, 0.25);
}

.sec__button {
  background-color: #fff;
  border: 1px solid #078ece;
  color: #078ece;
}

.sec__button:hover {
  background-color: #078ece;
  color: #f6f8fa;
  box-shadow: 0px 5px 5px 0px rgb(42 119 144 / 20%);
}

.loginEra {
  font-family: "Poppins", sans-serif;
  font-size: 0.75rem;
  color: red;
  font-weight: 400;
  text-align: center;
  display: block;
}
